import React from 'react';
import {Link} from 'gatsby';
import theme from '../../utils/theme';
import styled from 'styled-components';
import params from '../../utils/params';

const Container = styled.section`
  @media only screen and (${params.mediaQueryMaxWidthSmall}) {
    padding: 59px 19px 50px;
  }

  padding: 15px 50px;
  width: 100%;
  div {
    max-width: 1600px;

    @media (${params.mediaQueryMaxWidth2000}) {
      max-width: 1100px;
    }

    @media (${params.mediaQueryMaxWidth1440}) {
      max-width: 900px;
      align-items: flex-start;
    }

    @media (${params.mediaQueryMaxWidth1024}) {
      max-width: 700px;
    }

    @media (${params.mediaQueryMaxWidthLarge}) {
      max-width: 280px;
    }

    margin: 0 auto;
    a {
      line-height: 30px;
      text-decoration: none;
      color: ${theme.primaryLight};
      margin-right: 40px;
      font-size: 18px;
      display: inline-block;
    }
  }
`;

export default ({headers, pathname}) => {
  return (
    <Container>
      <div>
        {headers.map(header => {
          const link = header
            .split(' ')
            .join('-')
            .toLowerCase();
          return (
            <Link key={header} to={`${pathname}#${link}`}>
              {header}
            </Link>
          );
        })}
      </div>
    </Container>
  );
};
