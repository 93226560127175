import React from 'react';
import DomParser from 'dom-parser';
import styled from 'styled-components';
import theme from '../../utils/theme';
import params from '../../utils/params';
import {getPortalUrls} from '../../utils/api';

const Section = styled.section`
  padding: 50px;
  background: ${({lightBg}) => (lightBg ? theme.textWhite : theme.primaryBlue)};
  @media only screen and (max-width: 320px) {
    padding: 50px 19px 50px;
  }
`;

const Content = styled.div`
  max-width: 1600px;

  @media (${params.mediaQueryMaxWidth2000}) {
    max-width: 1100px;
  }

  @media (${params.mediaQueryMaxWidth1440}) {
    max-width: 900px;
    align-items: flex-start;
  }

  @media (${params.mediaQueryMaxWidth1024}) {
    max-width: 700px;
  }

  @media (${params.mediaQueryMaxWidthLarge}) {
    max-width: 280px;
  }

  margin: 0 auto;
  position: relative;
  white-space: normal;

  h1 {
    margin: 75px 0 24px;
    padding-top: 75px;
  }

  @media only screen and (${params.mediaQueryMaxWidthLarge}) {
    h1 {
      margin: 0px 0 24px;
      padding-top: 0;
    }
  }

  > div:first-child {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    > div > * {
      max-width: 534px;
    }
    > div:nth-child(1) {
      width: calc(100% - 481px);
      min-width: 280px;
    }
    > div:nth-child(2) {
      width: 481px;
    }
    @media only screen and (max-width: 481px) {
      > div:nth-child(2) {
        max-width: 100%;
      }
    }
  }

  > div:nth-child(2) {
    display: flex;
    margin-top: 60px;
    flex-wrap: wrap;
    > div {
      width: 25%;
      min-width: 221px;
      padding-right: 12px;
      h3 {
        margin-bottom: 20px;
      }
    }
  }

  a {
    text-decoration: none;
    color: ${theme.textWhite};
    background: ${theme.primaryLight};
    border-radius: 20px;
    padding: 6px 24px 8px;
    margin: 24px 0 0;
    display: inline-block;
    transition: 100ms;
  }
  a:hover {
    background: ${theme.primaryHover};
    color: ${theme.textWhite};
  }
  a:active {
    background: ${theme.primaryClick};
    color: ${theme.textWhite};
  }
  a.gatsby-resp-image-link {
    background: none;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
  }

  .gatsby-resp-image-background-image {
    background: none !important;
  }
`;

const Service = ({content, index}) => {
  const [config, setConfig] = React.useState({});
  React.useEffect(() => {
    if (Object.keys(config).length === 0) {
      getPortalUrls().then(response => setConfig(response));
    }
  });
  const parser = new DomParser();
  const headerNode = parser.parseFromString(content).getElementsByTagName('h1')[0];
  const headerWithId = `<h1 id="${headerNode.innerHTML
    .split(' ')
    .join('-')
    .toLowerCase()}">${headerNode.innerHTML}</h1>`;
  const linkNode = parser
    .parseFromString(content)
    .getElementsByTagName('a')
    .filter(node => !!node.textContent.trim()) //filter out gatsby's image links
    .map(node => ({
      html: node.outerHTML,
      newHTML: node.outerHTML.replace(node.getAttribute('href'), config['API_DOCS_URL'] || '/404'),
    }))[0];
  const modifiedContent = content
    .replace(headerNode.outerHTML, headerWithId)
    .replace(linkNode.html, linkNode.newHTML);

  return (
    <Section lightBg={index % 2 === 0}>
      <Content dangerouslySetInnerHTML={{__html: modifiedContent}} />
    </Section>
  );
};

export default Service;
