import React from 'react';
import {Location} from '@reach/router';
import DomParser from 'dom-parser';
import Hero from '../../Components/hero';
import Service from '../../Components/products/service';
import Subheader from '../../Components/products/subheader';
import Layout from '../../Components/layout';
import {filterByTypeSortByOrder} from '../../utils/utils';

const Products = ({edges, location: {pathname}}) => {
  const hero = edges.find(edge => edge.node.frontmatter.type === 'hero');
  const services = filterByTypeSortByOrder(edges, 'service');
  const parser = new DomParser();
  const serviceHeaders = services.map(service => {
    const parsedService = parser.parseFromString(service.node.html);
    const header = parsedService.getElementsByTagName('h1');
    return header[0].textContent;
  });

  return (
    <Layout>
      <Hero content={hero.node.html} />
      <Subheader headers={serviceHeaders} pathname={pathname} />
      {services.map((service, idx) => {
        return <Service key={idx} index={idx} content={service.node.html} />;
      })}
    </Layout>
  );
};

export default props => (
  <Location>{locationProps => <Products {...locationProps} {...props} />}</Location>
);
